const canvas = document.getElementById('gradientCanvas');
const ctx = canvas.getContext('2d');

function resizeCanvas() {
	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight;
}

resizeCanvas(); // Початкове встановлення розмірів канвасу
window.addEventListener('resize', resizeCanvas); // Зміна розмірів канвасу при зміні розміру вікна

const colors = ['#5BA9FC', '#2448C0']; // Масив кольорів
const circles = [];
const numCircles = 5; // Кількість кругів
const marginPercent = 15; // Мінімальна відстань від країв канвасу в процентах

const minRadiusArr = [125, 175, 200, 225, 250, 275];
const breakpointArr = [768, 992, 1440, 1680, 1920];
let minRadius = 0;
breakpointArr.map( (breakpoint, i) => {
	if ( i === 0 ) {
		if ( window.matchMedia( `(max-width: ${breakpointArr[i] - 1}px)` ).matches ) {
			minRadius = minRadiusArr[i];
		}
	} else if (i === breakpointArr - 1) {
		if ( window.matchMedia( `(min-width: ${breakpointArr[i]}px)` ).matches ) {
			minRadius = minRadiusArr[i];
		}
	} else {
		if ( window.matchMedia( `(min-width: ${breakpointArr[i - 1]}px) and (max-width: ${breakpointArr[i]}px)` ).matches ) {
			minRadius = minRadiusArr[i];
		}
	}
});
const maxRadius = minRadius * 2;

function hexToRgba(hex, alpha) {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function createRandomCircle(minRadius, maxRadius) {
	// const minRadius = canvas.width / 8;  // 1/4 ширини екрану
	// const maxRadius = canvas.width / 4;  // 1/2 ширини екрану
	const radius = Math.random() * (maxRadius - minRadius) + minRadius;

	const xPercent = Math.random() * (100 - 2 * marginPercent) + marginPercent;
	const yPercent = Math.random() * (100 - 2 * marginPercent) + marginPercent;

	const angleOffset = Math.random() * 2 * Math.PI;
	const color = colors[Math.floor(Math.random() * colors.length)];

	// Амплітуда та частота для прозорості
	const alphaAmplitude = Math.random() * 0.5 + 0.5;
	const alphaFrequency = Math.random() * 0.01 + 0.001; // Менша частота для повільніших змін

	return { xPercent, yPercent, radius, angleOffset, color, alphaAmplitude, alphaFrequency };
}

function drawCircle(circle, angle, time) {
	const { xPercent, yPercent, radius, angleOffset, color, alphaAmplitude, alphaFrequency } = circle;

	const x = (xPercent / 100) * canvas.width;
	const y = (yPercent / 100) * canvas.height;

	const offsetX = radius * Math.cos(angle + angleOffset) / 3;
	const offsetY = radius * Math.sin(angle + angleOffset) / 3;

	// Синусоїдальна функція для плавної зміни прозорості
	const alpha = alphaAmplitude * (Math.sin(time * alphaFrequency) / 2 + 0.5);

	const gradient = ctx.createRadialGradient(
		x + offsetX, y + offsetY, 0,
		x + offsetX, y + offsetY, radius
	);
	gradient.addColorStop(0, hexToRgba(color, alpha));
	gradient.addColorStop(1, hexToRgba(color, 0));

	ctx.beginPath();
	ctx.arc(x + offsetX, y + offsetY, radius, 0, 2 * Math.PI);
	ctx.globalCompositeOperation = 'overlay';
	ctx.fillStyle = gradient;
	ctx.fill();
}

function animate() {
	ctx.clearRect(0, 0, canvas.width, canvas.height);
	const angle = Date.now() / 1000; // Кут обертання
	const time = Date.now() / 5; // Точний час для обчислення прозорості

	circles.forEach(circle => {
		drawCircle(circle, angle, time);
	});

	requestAnimationFrame(animate);
}

for (let i = 0; i < numCircles; i++) {
	circles.push(createRandomCircle(minRadius, maxRadius));
}

animate();
